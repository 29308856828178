import { Card } from '@tremor/react'
import { Link, Outlet } from 'react-router'

export default function Layout({}: React.ComponentProps<'div'>) {
	return (
		<div className="flex min-h-svh flex-col items-center justify-center gap-6 bg-muted bg-neutral-50 p-6 md:p-10">
			<div className="w-full max-w-sm text-center md:max-w-3xl">
				<div className="flex flex-col gap-6">
					<Card className="overflow-hidden">
						<div className="grid p-0 md:grid-cols-2">
							<div className="p-6 md:p-8">
								<Outlet />
							</div>
							<div className="relative hidden bg-neutral-100 md:block">
								<div className="absolute inset-0 flex items-center justify-center dark:brightness-[0.2] dark:grayscale">
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="100"
										height="100"
										viewBox="0 0 100 100"
										fill="none"
									>
										<path
											fillRule="evenodd"
											clipRule="evenodd"
											d="M50 100C77.6142 100 100 77.6142 100 50C100 22.3858 77.6142 0 50 0C22.3858 0 0 22.3858 0 50C0 77.6142 22.3858 100 50 100ZM36 33C34.3431 33 33 34.3431 33 36V64C33 65.6569 34.3431 67 36 67H64C65.6569 67 67 65.6569 67 64V36C67 34.3431 65.6569 33 64 33H36Z"
											fill="black"
										></path>
									</svg>
								</div>
							</div>
						</div>
					</Card>
					<div className="text-balance text-center text-xs text-muted-foreground [&_a]:underline [&_a]:underline-offset-4 hover:[&_a]:text-primary">
						By continuing, you agree to our{' '}
						<Link to="/terms">Terms of Service</Link> and{' '}
						<Link to="/privacy">Privacy Policy</Link>.
					</div>
				</div>
			</div>
		</div>
	)
}
